import axios from 'axios'
//引入element-plus消息提示
import { ElMessage } from 'element-plus'
//引入用户相关的仓库
//使用axios对象create方法,创建axios实例(其他配置:基础路径、超时时间)
console.log(process.env.VUE_APP_BASE_URL)

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers['clientid'] = 'c33e44bd4a39aa5c105c1e67ae0aa10d';

const request = axios.create({
  //基础路径
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 5000, 
})



//添加请求与响应拦截器
request.interceptors.request.use((config) => {
  //获取用户相关的小仓库:获取仓库内部token,登录成功以后携带给服务器
  const getToken = () => {
    return localStorage.getItem('token')
  }
  const isToken = (config.headers || {}).isToken === false;
  if (getToken() && !isToken) {
    config.headers['Authorization'] = 'Bearer ' + getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  //config配置对象,headers属性请求头会给服务器端携带公共参数
  //返回配置对象
  return config
},(error)=>{
	//对请求错误做什么
	return Promise.reject(error)
})
//响应拦截器
request.interceptors.response.use(
  (response) => {
    //成功回调
    //简化数据
    return response.data
  },
  (error) => {
    //失败回调:处理http网络错误
    //定义一个变量:存储网络错误信息
    let message = ''
    //http状态码
    if(error && error.response && error.response.status){
      const status = error.response.status
      switch (status) {
        case 400:
          message = '请求错误(400)'
          break
        case 401:
          message = '未授权，请重新登录(401)'
          break
        case 403:
          message = '拒绝访问(403)'
          break
        case 404:
          message = '请求出错(404)'
          break
        case 408:
          message = '请求超时(408)'
          break
        case 500:
          message = '服务器错误(500)'
          break
        case 501:
          message = '服务未实现(501)'
          break
        case 502:
          message = '网络错误(502)'
          break
        case 503:
          message = '服务不可用(503)'
          break
        case 504:
          message = '网络超时(504)'
          break
        case 505:
          message = 'HTTP版本不受支持(505)'
          break
        case 1001:
          message = '验证失败'
          break
        default:
          message = `连接出错(${status})!`
      }
      //提示错误信息
      ElMessage({
        type: 'error',
        message,
      })
      return Promise.reject(error)
    } else {
      ElMessage({
        type: 'error',
        message: '网络异常，请检查网络连接!',
      })
    }
  },
)
//对外暴露
export default request