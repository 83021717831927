export function getIPUsingWebRTC() {
    return new Promise((resolve, reject) => {
        const pc = new RTCPeerConnection({ iceServers: [] });
        pc.createDataChannel('');
        pc.createOffer()
        .then(sdp => {
            console.log(sdp)
            if (typeof sdp === 'object') {
                // 假设sdp对象有一个名为description的属性包含相关字符串信息
                const sdpString = sdp.sdp || '';
                const ipRegex = /([0 - 9]{1,3}(\.[0 - 9]{1,3}){3}|[a - f0 - 9]{1,4}(:[a - f0 - 9]{1,4}){7})/g;
                const matches = sdpString.match(ipRegex);
                if (matches) {
                    resolve(matches[0]);
                } else {
                    reject(new Error('Could not find IP in SDP'));
                }
            } else if (typeof sdp === 'string') {
                const ipRegex = /([0 - 9]{1,3}(\.[0 - 9]{1,3}){3}|[a - f0 - 9]{1,4}(:[a - f0 - 9]{1,4}){7})/g;
                const matches = sdp.match(ipRegex);
                console.log(matches)
                if (matches) {
                    resolve(matches[0]);
                } else {
                    reject(new Error('Could not find IP in SDP'));
                }
            } else {
                reject(new Error('SDP is not a valid type'));
            }
          })
        .catch(reason => {
            reject(reason);
          });
        pc.onicecandidate = event => {
            if (event.candidate) {
                const ipRegex = /([0 - 9]{1,3}(\.[0 - 9]{1,3}){3}|[a - f0 - 9]{1,4}(:[a - f0 - 9]{1,4}){7})/g;
                const matches = event.candidate.candidate.match(ipRegex);
                if (matches) {
                    resolve(matches[0]);
                }
            }
        };
    });
}