import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'amfe-flexible'
// import 'amfe-flexible/index.min.js'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// 如果您正在使用CDN引入，请删除下面一行。
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// import Vant from 'vant'
// import 'vant/lib/index.css';
import Vant from 'vant';
import 'vant/lib/index.css';
// import uView from "uview-ui";
// Vue.use(Vant);

import { getBuryingPoint } from '@/api/buryingPoint'

router.beforeEach((to, from, next) => {
  // 在这里发送埋点请求
  console.log(to, from, '路由')
  next();
});

function initPoint () {
  const queryString = window.location.search;
  const regex = /code=([^&]+)/;
  const match = regex.exec(queryString);
  const code = match && match[1];

  getBuryingPoint({ nodeName: 'H5初始化加载', urlLink:  window.location.href, cellphone: '', channelName: '', channelCode: code })
  console.log('你好')
}
initPoint();
const app = createApp(App)
app.use(router)
app.use(ElementPlus)
// app.use(uView);
app.use(Vant)
app.mount('#app')
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
// createApp(App).use(router).use(ElementPlus).mount('#app')
