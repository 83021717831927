import { getIPUsingWebRTC } from '@/utils/getIPUsingWebRTC'
import request from '@/utils/request'


export const getBuryingPoint = async (param) => {
  const ip = await getIPUsingWebRTC()
  return request({
    url: '/point/add',
    method: 'post',
    data: { ...param },
  })
}