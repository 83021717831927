import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  // },
  {
    path: '/all-process',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  },
  {
    path: '/base',
    name: 'base',
    component: () => import(/* webpackChunkName: "about" */ '../views/BaseView.vue')
  },
  {
    path: '/ld-page',
    name: 'ldPage',
    component: () => import(/* webpackChunkName: "about" */ '../views/ldPage.vue')
  },
  {
    path: '/ld-base',
    name: 'ldBase',
    component: () => import(/* webpackChunkName: "about" */ '../views/LdBaseView.vue')
  },
  {
    path: '/semi-process',
    name: 'semiProcess',
    component: () => import(/* webpackChunkName: "about" */ '../views/applyFor.vue')
  },
  {
    path: '/apply-for',
    name: 'applyFor',
    // component: () => import(/* webpackChunkName: "about" */ '../views/applyFor.vue')
    component: () => import(/* webpackChunkName: "about" */ '../views/applyForTwo.vue')
  },
  // {
  //   path: '/apply-for-demo',
  //   name: 'applyFor',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/applyForTwo.vue')
  // },
  {
    path: '/succeed-page',
    name: 'succeedPage',
    component: () => import(/* webpackChunkName: "about" */ '../views/succeedPage.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
